import React from "react";

import Layout from '../components/layout'
import { Container } from "../components/container";
import { Footer } from "../components/Footer";

const NotFoundPage = () => (
    <Layout>
        <Container>
            <p>Page not found</p>
        </Container>
        <Footer/>
    </Layout>
);

export default NotFoundPage;
